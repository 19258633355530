import React from 'react'
import DocumentLayout from '../DocumentLayout'
import Centered from '../Centered'

const NotFoundPage = () => (
  <DocumentLayout className="subpage-white">
    <Centered>
      <br />
      <div className="spacing">
        <h1>Not Found</h1>
        <p>We're very sorry, but we can't find the page you were looking for.</p>
      </div>
    </Centered>
  </DocumentLayout>
)

export default NotFoundPage
